import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Page = ({ page: { title, featuredImage, content } }) => {
	return (
		<article className="section main-section wp-page">
			<div className="mx-auto">
				<div className="lg:w-4/5">
					<h1>{title}</h1>
					{content ? (
						<div dangerouslySetInnerHTML={{ __html: content }} />
					) : (
						<p>Sorry, no page data was found at this route.</p>
					)}
				</div>
			</div>
		</article>
	)
}

export default Page
